@import '../../fonts.css';
/* VARIABLES */
/* COLOR VARIABLES */
.box-frame {
  box-shadow: 5px 4px 16px 0 rgba(0, 0, 0, 0.03);
  border: 3px solid #d9d9d9;
}
.box-popup-frame {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #d9d9d9;
}
/* grey border used by antd divider */
* {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.hideScrollbar::-webkit-scrollbar {
  display: none;
}
body {
  font-family: 'Ageo', sans-serif;
  font-size: 0.9375rem;
  margin: 0;
}
a,
a:active,
a:hover {
  color: #e6008c;
}
/* Front page "Loading..." spinner */
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 0.9375rem;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #1890ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning.preloader {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-bottom: 25px;
}
.ant-spin-dot {
  position: absolute;
}
.ant-spin__text {
  padding-top: 70px;
  color: lightgrey;
}
/* <Button /> */
.ant-btn {
  height: 2.25rem;
  padding: 0.25rem 1.5rem;
  font-size: 0.9375rem;
}
.ant-btn span {
  padding-top: 2px;
}
.ant-btn-primary {
  background-color: #e6008c;
  border-color: #e6008c;
}
.ant-radio-group-solid > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #e6008c;
  border-color: #e6008c;
  box-shadow: -1px 0 0 0 #e6008c;
}
.ant-radio-group-solid > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus,
.ant-radio-group-solid > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #FFF;
  background-color: #b3006d;
  border-color: #e6008c;
}
.ant-radio-button-wrapper:hover {
  color: #e6008c;
}
.ant-btn.active,
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  color: #e6008c;
  border-color: #e6008c;
}
.ant-btn-primary.active,
.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  color: #FFF;
  background-color: #b3006d;
}
/* <Table /> */
.ant-table {
  font-size: 0.9375rem;
  font-weight: 400;
}
.ant-table .ant-table-tbody,
.ant-table .ant-table-body,
.ant-table .ant-table-thead,
.ant-table .ant-table-thead th {
  background-color: #FFF;
}
.ant-table .ant-table-thead th,
.ant-table .ant-table-tbody td {
  padding: 1em;
}
.ant-table .ant-table-thead div {
  font-weight: bold;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #EEEEEE;
}
.ant-table-tbody > tr.ant-table-expanded-row {
  background: #d9d9d9;
}
/* PAGINATION */
.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover,
.ant-pagination-item-active a,
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #e6008c;
}
/* <Menu /> */
.ant-layout-sider,
.ant-menu.ant-menu-dark {
  background-color: #555555;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  height: auto;
  padding: 0.33333333rem;
  font-size: 0.9375rem;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu .ant-menu-item {
  height: auto;
  padding-top: 0.33333333rem;
  padding-bottom: 0.33333333rem;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title a,
.ant-menu-inline .ant-menu-submenu .ant-menu-item a {
  color: rgba(255, 255, 255, 0.65);
  /* below trick fits the anchor tag to parent
       * div to increase the clickable area
       */
  display: list-item;
  list-style-type: none;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title i.ant-menu-submenu-arrow,
.ant-menu-inline .ant-menu-submenu .ant-menu-item i.ant-menu-submenu-arrow {
  top: 1.8rem;
}
.ant-btn > i,
.ant-btn > span {
  display: inline;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-submenu-open {
  background-color: #242424;
}
/* <Empty /> */
.ant-empty {
  margin: auto;
}
#app {
  height: 100vh;
  width: 100vw;
}
.ant-modal {
  top: 4rem;
}
.ant-modal-footer {
  border-top: none;
  padding-bottom: 1rem;
  text-align: center;
}
.ant-modal-footer .ant-divider {
  margin: 1rem;
  margin-top: 0.5rem;
}
.ant-modal-body {
  font-size: 0.9375rem;
  padding: 0 1.5rem 1rem;
}
.ant-modal-header {
  border-bottom: none;
  padding: 1rem 1.5rem 1.5rem;
}
.ant-modal-title {
  font-size: 1.5rem;
  padding: 1.5rem 1.5rem 0 0;
  text-transform: uppercase;
  text-align: center;
}
/* <Notification /> */
.ant-notification .notification-error {
  border: solid 2px #f5222d;
}
/* Responsive breakpoint variables */
/* Mixins for different screen size */
.clipboard-copy {
  color: #e6008c;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
.clipboard-copy::after {
  content: " \2398";
}
.announcements__button-new-container {
  padding-bottom: 0.5rem;
  margin: 0 1rem;
  border-bottom: 0.5px solid #e6008c;
}
.announcements--button-new {
  margin-right: 1rem;
  float: right;
}
.announcements--button-link {
  padding: 0;
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  color: #1890ff;
}
.announcements--button-link .anticon {
  margin-right: 1rem/4;
}
.announcements--button-link:hover,
.announcements--button-link :focus {
  text-decoration: underline;
  color: #1890ff;
  outline: none;
  border: 0;
}
.announcements__input-fields .ant-select {
  width: 100%;
}
.announcements__container {
  padding: 0 1rem;
  overflow: auto;
}
.announcements__announcement:not(:last-child) {
  border-bottom: 0.5px solid #e6008c;
  margin-bottom: 0.5rem;
}
.announcements__announcement .ant-card-head {
  min-height: 0;
  border-bottom: 0;
  padding: 0;
}
.announcements__announcement .ant-card-extra {
  padding: 0;
}
.announcements__announcement .ant-card-head-title {
  padding: 0;
  text-align: left;
}
.announcements__announcement .ant-card-head-title article {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: none;
}
.announcements__announcement > .ant-card-body {
  padding: 1rem 0;
}
.announcements__section-buttons {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.announcements__section-buttons--button-edit {
  padding: 0 1rem;
  margin-left: 1rem;
}
.announcements__link-delete-button {
  border: 0;
  outline: none;
}
.announcements__publish-date .ant-form-extra {
  padding-left: 0.5rem;
  font-size: small;
}
