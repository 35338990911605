@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('../fonts/3A7E52_0_0.woff2') format('woff2'), url('../fonts/3A7E52_0_0.woff') format('woff');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url('../fonts/3A7E52_1_0.woff2') format('woff2'), url('../fonts/3A7E52_1_0.woff') format('woff');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 800;
  font-display: auto;
  src: url('../fonts/3A7E52_2_0.woff2') format('woff2'), url('../fonts/3A7E52_2_0.woff') format('woff');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url('../fonts/3A7E52_3_0.woff2') format('woff2'), url('../fonts/3A7E52_3_0.woff') format('woff');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('../fonts/3A7E52_4_0.woff2') format('woff2'), url('../fonts/3A7E52_4_0.woff') format('woff');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url('../fonts/3A7E52_5_0.woff2') format('woff2'), url('../fonts/3A7E52_5_0.woff') format('woff');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url('../fonts/3A7E52_6_0.woff2') format('woff2'), url('../fonts/3A7E52_6_0.woff') format('woff');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 200;
  font-display: auto;
  src: url('../fonts/3A7E52_7_0.woff2') format('woff2'), url('../fonts/3A7E52_7_0.woff') format('woff');
}
