@import '../../fonts.css';
/* VARIABLES */
/* COLOR VARIABLES */
.box-frame {
  box-shadow: 5px 4px 16px 0 rgba(0, 0, 0, 0.03);
  border: 3px solid #d9d9d9;
}
.box-popup-frame {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #d9d9d9;
}
/* grey border used by antd divider */
* {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.hideScrollbar::-webkit-scrollbar {
  display: none;
}
body {
  font-family: 'Ageo', sans-serif;
  font-size: 0.9375rem;
  margin: 0;
}
a,
a:active,
a:hover {
  color: #e6008c;
}
/* Front page "Loading..." spinner */
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 0.9375rem;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #1890ff;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning.preloader {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-bottom: 25px;
}
.ant-spin-dot {
  position: absolute;
}
.ant-spin__text {
  padding-top: 70px;
  color: lightgrey;
}
/* <Button /> */
.ant-btn {
  height: 2.25rem;
  padding: 0.25rem 1.5rem;
  font-size: 0.9375rem;
}
.ant-btn span {
  padding-top: 2px;
}
.ant-btn-primary {
  background-color: #e6008c;
  border-color: #e6008c;
}
.ant-radio-group-solid > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #e6008c;
  border-color: #e6008c;
  box-shadow: -1px 0 0 0 #e6008c;
}
.ant-radio-group-solid > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus,
.ant-radio-group-solid > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #FFF;
  background-color: #b3006d;
  border-color: #e6008c;
}
.ant-radio-button-wrapper:hover {
  color: #e6008c;
}
.ant-btn.active,
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  color: #e6008c;
  border-color: #e6008c;
}
.ant-btn-primary.active,
.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  color: #FFF;
  background-color: #b3006d;
}
/* <Table /> */
.ant-table {
  font-size: 0.9375rem;
  font-weight: 400;
}
.ant-table .ant-table-tbody,
.ant-table .ant-table-body,
.ant-table .ant-table-thead,
.ant-table .ant-table-thead th {
  background-color: #FFF;
}
.ant-table .ant-table-thead th,
.ant-table .ant-table-tbody td {
  padding: 1em;
}
.ant-table .ant-table-thead div {
  font-weight: bold;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #EEEEEE;
}
.ant-table-tbody > tr.ant-table-expanded-row {
  background: #d9d9d9;
}
/* PAGINATION */
.ant-pagination-item-active,
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover,
.ant-pagination-item-active a,
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #e6008c;
}
/* <Menu /> */
.ant-layout-sider,
.ant-menu.ant-menu-dark {
  background-color: #555555;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  height: auto;
  padding: 0.33333333rem;
  font-size: 0.9375rem;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu .ant-menu-item {
  height: auto;
  padding-top: 0.33333333rem;
  padding-bottom: 0.33333333rem;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title a,
.ant-menu-inline .ant-menu-submenu .ant-menu-item a {
  color: rgba(255, 255, 255, 0.65);
  /* below trick fits the anchor tag to parent
       * div to increase the clickable area
       */
  display: list-item;
  list-style-type: none;
}
.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title i.ant-menu-submenu-arrow,
.ant-menu-inline .ant-menu-submenu .ant-menu-item i.ant-menu-submenu-arrow {
  top: 1.8rem;
}
.ant-btn > i,
.ant-btn > span {
  display: inline;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-submenu-open {
  background-color: #242424;
}
/* <Empty /> */
.ant-empty {
  margin: auto;
}
#app {
  height: 100vh;
  width: 100vw;
}
.ant-modal {
  top: 4rem;
}
.ant-modal-footer {
  border-top: none;
  padding-bottom: 1rem;
  text-align: center;
}
.ant-modal-footer .ant-divider {
  margin: 1rem;
  margin-top: 0.5rem;
}
.ant-modal-body {
  font-size: 0.9375rem;
  padding: 0 1.5rem 1rem;
}
.ant-modal-header {
  border-bottom: none;
  padding: 1rem 1.5rem 1.5rem;
}
.ant-modal-title {
  font-size: 1.5rem;
  padding: 1.5rem 1.5rem 0 0;
  text-transform: uppercase;
  text-align: center;
}
/* <Notification /> */
.ant-notification .notification-error {
  border: solid 2px #f5222d;
}
/* Responsive breakpoint variables */
/* Mixins for different screen size */
.clipboard-copy {
  color: #e6008c;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
.clipboard-copy::after {
  content: " \2398";
}
.login {
  display: flex;
  height: 100vh;
  width: 100vw;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  overflow: scroll;
  /**  ---- Override Amplify user input form's css (login, forgot password) ----- */
}
.login * {
  font-family: 'Ageo', sans-serif;
}
.login [data-amplify-container] {
  padding: 3rem;
  position: relative;
  max-width: 28rem;
  background-color: #fff;
  box-shadow: 5px 4px 16px #00000008;
  border: 3px solid #d9d9d9;
}
.login .amplify-alert {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 5px #0000004d;
  padding: 16px;
  background-color: #31465f;
  font-size: 14px;
  color: #fff;
  box-sizing: border-box;
}
.login .amplify-alert .amplify-button[data-variation=link] {
  background-color: transparent;
  padding: 0;
}
.login .amplify-alert .amplify-button[data-variation=link]:hover {
  background-color: transparent;
}
.login .amplify-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}
.login .amplify-icon:hover {
  color: #FFF;
}
.login .amplify-tabs {
  display: none;
}
.login .amplify-button {
  font-size: 0.9375rem;
  font-weight: 400;
  border: none;
}
.login .amplify-button:hover {
  cursor: pointer;
}
.login .amplify-loader {
  display: none;
}
.login .amplify-button[data-variation='primary'] {
  /* stylelint-disable-next-line CssSyntaxError */
  background-color: #e6008c;
  border-color: #e6008c;
  width: 100px;
  height: 36px;
  color: #fff;
  box-shadow: 0 2px #0000000b;
  border: none;
  border-radius: 4px;
}
.login .amplify-button[data-variation='primary']:hover {
  color: #fff;
  background-color: #b3006d;
  cursor: pointer;
}
.login .amplify-button[data-variation='link'] {
  color: #e6008c;
  border: none;
  background-color: #FFF;
}
.login .amplify-button[data-variation='link']:hover {
  background-color: #FFF;
  border: none;
  cursor: pointer;
}
.login .amplify-label {
  font-size: 0.9375rem;
  font-weight: 400;
  padding: 0 0 24px;
}
.login .amplify-flex {
  height: auto;
  margin: 10px 0 10px 0;
}
.login .amplify-textfield {
  text-align: left;
  margin: 0 0 24px;
}
.login input.amplify-input {
  font-size: 0.9375rem;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-end-end-radius: 4px;
  border-start-end-radius: 4px;
  width: 100%;
  height: 32px;
}
.login input.amplify-input:focus,
.login input.amplify-input:hover,
.login input.amplify-input:active {
  border-color: #1890ff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.login [data-amplify-router] {
  padding: 24px;
  zoom: 1;
}
.login .amplify-button.amplify-field__show-password {
  display: none;
}
.login form[data-amplify-authenticator-confirmsignin] fieldset div:nth-child(3),
.login form[data-amplify-authenticator-resetpassword] fieldset div:nth-child(3) {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: space-between;
}
.login .amplify-visually-hidden {
  display: none;
}
.login__container {
  padding: 3rem;
  width: 60%;
  max-width: 28rem;
  background-color: #FFF;
  box-shadow: 5px 4px 16px 0 rgba(0, 0, 0, 0.03);
  border: 3px solid #d9d9d9;
}
@media only screen and (max-width: 575px) {
  .login__container {
    width: 80%;
    margin-top: 6rem;
    padding: 2rem;
  }
}
.login__container__logo {
  height: 2.5rem;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.login__container__forgot-password {
  display: flex;
  justify-content: center;
}
.login__container__forgot-password__button.amplify-button {
  color: #e6008c;
  border: 0;
  background-color: transparent;
}
.login__container__register__button.amplify-button {
  height: 2.25rem;
  padding: 0.25rem 1.5rem;
  font-size: 0.9375rem;
  background: #3e3e3e;
  color: #FFF;
  margin-top: 10px;
  border-radius: 4px;
  line-height: 30px;
}
.login__container__register__button.amplify-button span {
  line-height: 1;
}
.login__container__register__button.active,
.login__container__register__button:active,
.login__container__register__button:focus,
.login__container__register__button:hover {
  background: #252525;
  border-color: #3e3e3e;
  color: #fff;
  cursor: pointer;
}
